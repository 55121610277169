






















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_NEW_BANK_ACCOUNTS } from '../store/bankAccount';
import { ApiResponse } from '@/components/types';
import { ImportSEPAAccount, ListNewSEPAAccounts } from '../types';
import { createListNewSEPAAccounts } from '@/modules/listOfAssets/model';
import BankSelect from '@/modules/bank/components/BankSelect.vue';

const NewSepaAccountCard = () => import('@/modules/listOfAssets/components/NewSepaAccountCard.vue');

const BankAccount = namespace('listOfAssets/bankAccount');

@Component({
  components: {
    BankSelect,
    NewSepaAccountCard
  }
})
export default class FinTSAccountFinder extends Vue {
  @BankAccount.Action(FETCH_NEW_BANK_ACCOUNTS) fetchAccounts!: (params: ListNewSEPAAccounts) => Promise<ApiResponse<ImportSEPAAccount[]>>;

  error: any = null;

  listNewSEPAAccounts = createListNewSEPAAccounts();

  accounts: ImportSEPAAccount[] = [];
  loading = false;
  loaded = false;

  removeItem(iban: string) {
    this.accounts = this.accounts.filter((account) => account.iban !== iban);
  }

  async findAccounts() {
    this.error = null;

    return this.fetchAccounts(this.listNewSEPAAccounts);
  }

  onSuccess(accounts: ImportSEPAAccount[]) {
    this.accounts = accounts;
    this.loaded = true;
  }

  onError(error: any) {
    this.error = error;
    this.loaded = true;
  }

  reset(observer: any) {
    this.listNewSEPAAccounts = createListNewSEPAAccounts();
    this.accounts = [];

    setTimeout(() => {
      this.loading = false;
      this.loaded = false;
      this.error = null;
      observer.reset();
    }, 0);
  }
}
